import { EditorSDK, PageRef } from '@wix/platform-editor-sdk';
import type { EditorReadyOptions } from '@wix/platform-editor-sdk';
import { createWidgetDefinition } from './create-widget-definition';
import { createSectionDefinition } from './create-section-definition';
import { CompRef } from '@wix/document-services-types';
import {
  DONATIONS_APP_DEF_ID,
  DONATIONS_WIDGET_ID,
  DONATIONS_PAGE_ID,
} from '../constants/app';
import { DESKTOP_PRESET_ID, MOBILE_PRESET_ID } from '../constants/presets';

export async function handleFirstInstall(
  editorSDK: EditorSDK,
  options: EditorReadyOptions,
): Promise<void> {
  const isResponsive = options.origin.type === 'RESPONSIVE';
  const pageRef = await addPage(editorSDK, isResponsive);
  if (!isResponsive) {
    setTimeout(() => {
      addWidgetWithPresets(editorSDK, pageRef);
    }, 1000);
  }
}

const addPage = async (
  editorSDK: EditorSDK,
  isResponsive: boolean,
): Promise<PageRef> => {
  const tpaApplicationId = (
    await editorSDK.tpa.app.getDataByAppDefId('', DONATIONS_APP_DEF_ID)
  ).applicationId;
  return editorSDK.pages.add('', {
    title: 'Donate',
    definition: {
      id: '',
      type: 'Page',
      components: isResponsive
        ? [
            createSectionDefinition([
              createWidgetDefinition({
                appDefinitionId: DONATIONS_APP_DEF_ID,
                widgetId: DONATIONS_WIDGET_ID,
                presetId: DESKTOP_PRESET_ID,
              }),
            ]) as any,
          ]
        : [],
      data: {
        managingAppDefId: DONATIONS_APP_DEF_ID,
        tpaApplicationId,
        tpaPageId: DONATIONS_PAGE_ID,
        pageUriSEO: 'donate',
      },
      componentType: 'mobile.core.components.Page',
    },
    shouldAddMenuItem: true,
    shouldNavigateToPage: true,
  });
};

function addWidgetWithPresets(editorSDK: EditorSDK, pageRef: PageRef) {
  return editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: DONATIONS_WIDGET_ID,
    layout: {
      x: 0,
      y: 0,
      fixedPosition: false,
      width: 980,
      height: 90,
      scale: 1,
      rotationInDegrees: 0,
    },
    scopedPresets: {
      desktop: { layout: DESKTOP_PRESET_ID, style: DESKTOP_PRESET_ID },
      mobile: { layout: MOBILE_PRESET_ID, style: MOBILE_PRESET_ID },
    },
    installationType: 'closed',
    containerRef: pageRef as CompRef,
  });
}
