import { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { handleFirstInstall } from './first-install/handle-first-install';
import { AppManifest } from '@wix/app-manifest-builder';
import { buildAppManifest } from './manifest/app-manifest';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  _appDefId,
  options,
  _flowAPI,
) => {
  if (options.firstInstall) {
    await handleFirstInstall(editorSDK, options);
  }
};

export const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
): Promise<AppManifest> => {
  return buildAppManifest(options, editorSDK);
};
