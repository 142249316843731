
  var editorScriptEntry = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = null;
      const experimentsConfig = {"centralized":true,"scopes":[]};
      const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};
      const defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'hack22-donations',
        appDefId: '5d4350f4-dbef-4f05-90b0-9d875f9ada57',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donation App/editor.controller.ts');

      const model_0 = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donation App/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "5d4350f4-dbef-4f05-90b0-9d875f9ada57-urqxv";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"5d4350f4-dbef-4f05-90b0-9d875f9ada57-urqxv": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57-urqxv", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);
  
      const userController_1 = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donation form/editor.controller.ts');

      const model_1 = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donation form/model.ts').default;

      const manifest_1 = userController_1 ? userController_1.default || userController_1 : {};
      if (!manifest_1.type) {
        manifest_1.type = "5d4350f4-dbef-4f05-90b0-9d875f9ada57-ccarp";
      }
      if (!manifest_1.getExports && model_1) {
        manifest_1.getExports = () => ({"5d4350f4-dbef-4f05-90b0-9d875f9ada57-ccarp": model_1.getExports()});
      }
      if (userController_1 && userController_1.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57-ccarp", getWidgetManifest: userController_1.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_1);
  
      const userController_2 = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donations/editor.controller.ts');

      const model_2 = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donations/model.ts').default;

      const manifest_2 = userController_2 ? userController_2.default || userController_2 : {};
      if (!manifest_2.type) {
        manifest_2.type = "5d4350f4-dbef-4f05-90b0-9d875f9ada57-kaeap";
      }
      if (!manifest_2.getExports && model_2) {
        manifest_2.getExports = () => ({"5d4350f4-dbef-4f05-90b0-9d875f9ada57-kaeap": model_2.getExports()});
      }
      if (userController_2 && userController_2.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57-kaeap", getWidgetManifest: userController_2.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_2);
  
      const userController_3 = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donor Feed/editor.controller.ts');

      const model_3 = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Donor Feed/model.ts').default;

      const manifest_3 = userController_3 ? userController_3.default || userController_3 : {};
      if (!manifest_3.type) {
        manifest_3.type = "5d4350f4-dbef-4f05-90b0-9d875f9ada57-nqe6o";
      }
      if (!manifest_3.getExports && model_3) {
        manifest_3.getExports = () => ({"5d4350f4-dbef-4f05-90b0-9d875f9ada57-nqe6o": model_3.getExports()});
      }
      if (userController_3 && userController_3.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57-nqe6o", getWidgetManifest: userController_3.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_3);
  
      const userController_4 = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Progress bar/editor.controller.ts');

      const model_4 = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Progress bar/model.ts').default;

      const manifest_4 = userController_4 ? userController_4.default || userController_4 : {};
      if (!manifest_4.type) {
        manifest_4.type = "5d4350f4-dbef-4f05-90b0-9d875f9ada57-x9tr9";
      }
      if (!manifest_4.getExports && model_4) {
        manifest_4.getExports = () => ({"5d4350f4-dbef-4f05-90b0-9d875f9ada57-x9tr9": model_4.getExports()});
      }
      if (userController_4 && userController_4.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57-x9tr9", getWidgetManifest: userController_4.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_4);
  
      const userController_5 = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Repeater with items/editor.controller.ts');

      const model_5 = require('/home/builduser/work/cec612708f479ac4/packages/hack22-donations/src/components/Repeater with items/model.ts').default;

      const manifest_5 = userController_5 ? userController_5.default || userController_5 : {};
      if (!manifest_5.type) {
        manifest_5.type = "5d4350f4-dbef-4f05-90b0-9d875f9ada57-lk5qk";
      }
      if (!manifest_5.getExports && model_5) {
        manifest_5.getExports = () => ({"5d4350f4-dbef-4f05-90b0-9d875f9ada57-lk5qk": model_5.getExports()});
      }
      if (userController_5 && userController_5.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "5d4350f4-dbef-4f05-90b0-9d875f9ada57-lk5qk", getWidgetManifest: userController_5.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_5);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
