import { AppManifest, AppManifestBuilder } from '@wix/app-manifest-builder';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { EditorSDK } from '@wix/platform-editor-sdk';

export const buildAppManifest = async (
  options,
  editorSDK: EditorSDK,
): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options as any);
  return (appManifestBuilder as AppManifestBuilder)
    .withJsonManifest(baseManifest)
    .configurePages((pagesBuilder) => {
      pagesBuilder.addAction(
        {
          type: 'page_set_as_homepage',
        },
        {
          type: 'page_rename',
        },
        {
          type: 'page_delete',
        },
      );
    })
    .configurePagesTab((pagesTabBuilder) => {
      pagesTabBuilder.addAction({
        title: 'Delete Donations',
        icon: 'deleteRadio',
        onClick: () => editorSDK.tpa.app.delete(''),
      });
    })
    .build();
};
